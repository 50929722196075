import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upsellTransition, UpsellTransitionStages } from '@experian-uk/web-common-mobile-view-helpers';

import fulfillSubscription from '../../actions/fulfillments/fulfill';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import Loading from '../Loading';
import paymentCompleteTransition from '../PaymentOrchestrator/transitions/paymentCompleteTransition';
import { trackSwitchFulfillmentAttempt, trackSwitchFulfillmentStarted } from '../../helpers/switch/analytics';
import useTrackUpsellStage from '../PaymentOrchestrator/hooks/useTrackUpsellStage';
import { PAYMENT_LOG_STEPS } from '../../constants/paymentLogSteps';

const FULFILLMENT_UNKNOWN_STATUS = 'FULFILLMENT_UNKNOWN';

const PaymentProcessing = () => {
  const paymentContext = useContext(PaymentContext);
  const { upgradeOffer } = paymentContext;
  const fulfillmentStatus = useSelector(state => state.fulfillments.success ?? FULFILLMENT_UNKNOWN_STATUS);

  const trackUpsellStage = useTrackUpsellStage();
  const dispatch = useDispatch();

  useEffect(() => {
    trackSwitchFulfillmentStarted();
  }, []);

  useEffect(() => {
    trackUpsellStage(PAYMENT_LOG_STEPS.SUBSCRIPTION_FULFILLMENT_STARTED);
  }, [trackUpsellStage]);

  // prevent mobile navigation and start the fulfillment process
  useEffect(() => {
    upsellTransition({ stage: UpsellTransitionStages.LOCK });
    dispatch(fulfillSubscription(upgradeOffer.id));
  }, [dispatch, upgradeOffer]);

  // listen for a fulfillment result and trigger the next transition (thanks or error/reset)
  useEffect(() => {
    if (fulfillmentStatus !== FULFILLMENT_UNKNOWN_STATUS) {
      const fulfillmentsSuccessful = fulfillmentStatus === true;

      trackSwitchFulfillmentAttempt(fulfillmentsSuccessful);
      trackUpsellStage(
        fulfillmentsSuccessful
          ? PAYMENT_LOG_STEPS.SUBSCRIPTION_FULFILLED
          : PAYMENT_LOG_STEPS.SUBSCRIPTION_FULFILLMENT_FAILED
      );

      upsellTransition({ stage: UpsellTransitionStages.UNLOCK });
      dispatch(paymentCompleteTransition(paymentContext, fulfillmentStatus));
    }
  }, [dispatch, fulfillmentStatus, paymentContext]);

  return <Loading solidBackground />;
};

export default PaymentProcessing;
