import React from 'react';
import PropTypes from 'prop-types';

import Select, { SelectState } from '@nebula/select';
import { expiryDateFields } from '../../../NewCard/coreFields';
import { FieldSet, DateSelectorContainer } from './cardExpiryDate.styles';

const expiryDate = expiryDateFields(true);

const CardExpiryDate = fields => {
  const touchedYear = fields.expirationYear.meta.touched;
  const {
    meta: { error, touched },
  } = fields.expirationMonth;
  const helperMessage = error && touched && touchedYear ? 'Please enter a valid end date' : null;

  return (
    <FieldSet>
      {fields.names.map(fieldName => (
        <DateSelectorContainer key={fieldName} name={fieldName}>
          <Select
            config={expiryDate.options[fieldName].map(option => ({
              label: `${option.description}`,
              value: `${option.key}`,
            }))}
            {...fields[fieldName].input}
            name={fieldName}
            testId={expiryDate.props[fieldName].automation}
            placeholder={expiryDate.blanks[fieldName]}
            label={fieldName === 'expirationMonth' ? 'Card expiry date' : ''}
            helper={fieldName === 'expirationMonth' ? helperMessage : ''}
            state={helperMessage ? SelectState.Error : SelectState.Standard}
            width="100%"
          />
        </DateSelectorContainer>
      ))}
    </FieldSet>
  );
};

CardExpiryDate.propTypes = {
  fields: PropTypes.shape({
    expirationMonth: PropTypes.shape({
      input: PropTypes.shape({}),
      meta: PropTypes.shape({}),
    }),
    expirationYear: PropTypes.shape({
      input: PropTypes.shape({}),
      meta: PropTypes.shape({}),
    }),
    fields: PropTypes.shape({}),
    names: PropTypes.arrayOf(PropTypes.string),
  }),
};

CardExpiryDate.defaultProps = {
  fields: {
    expirationMonth: {
      input: {},
      meta: {},
    },
    expirationYear: {
      input: {},
      meta: {},
    },
    fields: {},
    names: [],
  },
};

export default CardExpiryDate;
