import get from 'lodash/get';
import Analytics, { MilestoneInputEvent } from '@experian-uk/web-common-analytics';
import fetch from '../fetch';
import errorMessages from './errorMessages';
import { defined } from '../../helpers/defined';
import CardSaveError from '../../exceptions/cardSaveError';
import { getEnv } from '../../components/Context/env';
import initializeEcd from '../ecd/initialize';
import { resolveChallengeStatus } from './resolveChallengeStatus';
import DirectDebitSaveError from '../../exceptions/directDebitSaveError';
import { maxTimeout, pollingInterval } from '../../constants/polling3ds';
import {
  applePayPaymentRequest,
  generateApplePayLineItem,
  validateMerchant,
  recurringPaymentRequest,
  canMakeApplePayPayments,
} from '../../components/SubscriptionAndPaymentMethods/PaymentInformation/applePay/applePayHelpers';
import {
  publishOnClickApplePayECD,
  publishOnClickCancelECD,
  publishOnErrorECD,
  publishPaymentMethodUpdatedECD,
  publishOnErrorApplePayECD,
  paymentUpdated,
  paymentUpdateFailed,
} from '../../components/SubscriptionAndPaymentMethods/PaymentInformation/paymentInformationsEcds';
import { applePay } from '../../constants';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const ADD_DD_PAYMENT_METHOD = 'ADD_DD_PAYMENT_METHOD';
export const ADD_APPLEPAY_PAYMENT_METHOD = 'ADD_APPLEPAY_PAYMENT_METHOD';
export const APPLEPAY_PAYMENT_METHOD_SAVED = 'APPLEPAY_PAYMENT_METHOD_SAVED';
export const APPLEPAY_PAYMENT_METHOD_FAILED = 'APPLEPAY_PAYMENT_METHOD_FAILED';
export const DD_PAYMENT_METHOD_SAVED = 'DD_PAYMENT_METHOD_SAVED';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const NO_PAYMENT_METHODS = 'NO_PAYMENT_METHODS';
export const paymentMethodsRetrievalEndpoint = 'billingsubscriptions/paymentmethods';
export const paymentMethodsEndpoint = 'billingpaymentmethods';
export const paymentAuthEndpoint = 'billingpaymentauthorizations';
export const PAYMENT_METHODS_FETCHED = 'PAYMENT_METHODS_FETCHED';
export const PAYMENT_METHOD_SAVED = 'PAYMENT_METHOD_SAVED';
export const PAYMENT_METHOD_CHALLENGED = 'PAYMENT_METHOD_CHALLENGED';
export const RESET_CARD_SAVED = 'RESET_CARD_SAVED';

export const getPaymentMethods = () => async dispatch => {
  dispatch({ type: FETCH_PAYMENT_METHODS });

  const dispatchAction = {
    type: NO_PAYMENT_METHODS,
    payload: {},
  };

  try {
    const paymentMethods = await dispatch(fetch(`/${paymentMethodsRetrievalEndpoint}`));
    if (paymentMethods && paymentMethods.data) {
      dispatchAction.type = PAYMENT_METHODS_FETCHED;
      dispatchAction.payload = { paymentMethods: paymentMethods.data };
    }
  } catch (error) {
    dispatchAction.type = PAYMENT_METHODS_FETCHED;
    dispatchAction.error = true;
    dispatchAction.payload = new Error(JSON.stringify(errorMessages.getPaymentMethods));
    dispatchAction.meta = { critical: false };
  }

  return dispatch(dispatchAction);
};

export const addPaymentMethod = (card, subscriptionId, initialPaymentMethod) => async dispatch => {
  const ECDUpdateMethodText = `${initialPaymentMethod}->${applePay.cardOption}`;
  dispatch({ type: ADD_PAYMENT_METHOD });

  const env = getEnv();

  if (!defined('object', card)) {
    publishPaymentMethodUpdatedECD({
      updateStatus: paymentUpdateFailed,
      attemptedUpdateMethod: ECDUpdateMethodText,
    });
    return dispatch({
      type: PAYMENT_METHOD_SAVED,
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.validation)),
    });
  }

  const request = {
    method: 'PUT',
    body: {
      creditCardInfo: {
        ...card,
        isDefaultCard: true,
      },
      browser: {
        acceptHeader: '',
        screenColourDepth: window.screen.colorDepth,
        javaScriptEnabled: true,
        language: 'en',
        remoteAddress: '',
        screenResolution: `${window.screen.availWidth}X${window.screen.availHeight}`,
        timezone: new Date().getTimezoneOffset().toString(),
        userAgentHeader: navigator.userAgent,
      },
      offerId: subscriptionId,
      paymentType: 'creditcard',
      paymentMethodChange: true,
      returnurl: env.REACT_APP_RETURN_URL_3DS,
    },
  };
  try {
    const authRes = await dispatch(
      fetch(`/${paymentAuthEndpoint}`, request, {
        require3dsHeaders: true,
      })
    );

    const challengeUrl = authRes.data[0].challengeRedirectUrl;
    const success = !!get(authRes, 'data[0].success', false);

    // 3DS Challenge not required where challengeUrl is empty or not returned
    if (success && !challengeUrl) {
      Analytics.publishOnce(
        MilestoneInputEvent.fromObject({
          product_movement: {
            journey_type: 'Add New Card',
            page_funnel_step: 'Save - Step 1',
          },
        })
      );
      publishPaymentMethodUpdatedECD({
        updateStatus: paymentUpdated,
        attemptedUpdateMethod: ECDUpdateMethodText,
      });
      dispatch(initializeEcd());
      return dispatch({
        type: PAYMENT_METHOD_SAVED,
        payload: {
          card: {
            ...card,
            expirationMonth: parseInt(card.expirationMonth, 10),
            expirationYear: parseInt(card.expirationYear, 10),
          },
          cardSaved: true,
        },
      });
    }
    Analytics.publishOnce(
      MilestoneInputEvent.fromObject({
        product_movement: {
          journey_type: 'Add New Card',
          page_funnel_step: '3DS Challenge - Step 1',
        },
      })
    );
    dispatch(initializeEcd());
    dispatch({
      type: PAYMENT_METHOD_CHALLENGED,
      payload: { challengeUrl },
    });

    // Begin polling challenge status
    const challengeResponse = await resolveChallengeStatus(paymentAuthEndpoint, dispatch, maxTimeout, pollingInterval);
    switch (challengeResponse) {
      case 'Success':
        publishPaymentMethodUpdatedECD({
          updateStatus: paymentUpdated,
          attemptedUpdateMethod: ECDUpdateMethodText,
        });
        return dispatch({
          type: PAYMENT_METHOD_SAVED,
          payload: {
            card: {
              ...card,
              expirationMonth: parseInt(card.expirationMonth, 10),
              expirationYear: parseInt(card.expirationYear, 10),
            },
            cardSaved: true,
          },
        });
      case 'Stopped':
        publishPaymentMethodUpdatedECD({
          updateStatus: paymentUpdateFailed,
          attemptedUpdateMethod: ECDUpdateMethodText,
        });
        return false;
      case 'ServiceError':
      default:
        // For 'Fail' response from timeout or unauthorised
        publishPaymentMethodUpdatedECD({
          updateStatus: paymentUpdateFailed,
          attemptedUpdateMethod: ECDUpdateMethodText,
        });
        return dispatch({
          error: true,
          payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(null, 'threeDSValidationError'))),
          type: PAYMENT_METHOD_SAVED,
        });
    }
  } catch (error) {
    publishPaymentMethodUpdatedECD({
      updateStatus: paymentUpdateFailed,
      attemptedUpdateMethod: ECDUpdateMethodText,
    });
    return dispatch({
      type: NO_PAYMENT_METHODS,
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.cardAuthorization(error.status, 'addPaymentMethod'))),
    });
  }
};

export const addDDPaymentMethod = (directDebitDetails, offerId) => async dispatch => {
  dispatch({ type: ADD_DD_PAYMENT_METHOD });

  if (!defined('object', directDebitDetails) || !offerId) {
    return dispatch({
      type: DD_PAYMENT_METHOD_SAVED,
      error: true,
      payload: new DirectDebitSaveError(JSON.stringify(errorMessages.ddValidation)),
    });
  }

  const request = {
    method: 'PUT',
    body: {
      AccountHolderName: directDebitDetails.accountHolderName,
      AccountNumber: directDebitDetails.accountNumber,
      SortCode: directDebitDetails.sortCode,
      OfferId: offerId,
    },
  };

  try {
    const result = await dispatch(fetch(`/${paymentMethodsEndpoint}/directdebit`, request));
    if (get(result, 'data[0].success', false)) {
      return dispatch({
        type: DD_PAYMENT_METHOD_SAVED,
        payload: {
          directDebitDetails,
          directDebitSaved: true,
        },
      });
    }
    return dispatch({
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.ddAuthorization(null, 'addDDPaymentMethod'))),
      type: DD_PAYMENT_METHOD_SAVED,
    });
  } catch (error) {
    return dispatch({
      error: true,
      payload: new CardSaveError(JSON.stringify(errorMessages.ddAuthorization(error.status, 'addDDPaymentMethod'))),
      type: DD_PAYMENT_METHOD_SAVED,
    });
  }
};

export const resetCardSaved = () => async dispatch =>
  dispatch({
    type: RESET_CARD_SAVED,
  });

export const addApplePayPaymentMethod =
  (currentSubscription, currentSubscriptionPrice, nextBillDate, initialPaymentMethod) => async dispatch => {
    dispatch({ type: ADD_APPLEPAY_PAYMENT_METHOD });
    const env = getEnv();
    const ECDUpdateMethodText = `${initialPaymentMethod}->${applePay.applePayOption}`;
    publishOnClickApplePayECD();
    let session;
    if (canMakeApplePayPayments()) {
      try {
        const applePayLineItem = generateApplePayLineItem(currentSubscription, currentSubscriptionPrice, nextBillDate);
        session = new window.ApplePaySession(3, applePayPaymentRequest(currentSubscriptionPrice, applePayLineItem));
        session.onvalidatemerchant = () => validateMerchant(session, dispatch, ECDUpdateMethodText);
        session.onpaymentmethodselected = () => {
          const applePayPaymentUpdateObj = {
            newTotal: applePayLineItem,
            newLineItems: [applePayLineItem],
            newRecurringPaymentRequest: recurringPaymentRequest(applePayLineItem, env.REACT_APP_PUBLIC_HOST),
          };
          session.completePaymentMethodSelection(applePayPaymentUpdateObj);
        };
        session.onpaymentauthorized = async event => {
          const applePayToken = event.payment.token;
          const request = {
            method: 'POST',
            body: {
              appleTokenInfo: {
                displayName: applePayToken.paymentMethod.displayName,
                network: applePayToken.paymentMethod.network,
                paymentData: applePayToken.paymentData,
                transactionIdentifier: applePayToken.transactionIdentifier,
                paymentType: 'applePay',
              },
            },
          };
          try {
            const result = await dispatch(fetch(`/${paymentMethodsEndpoint}`, request));
            if (get(result, 'data[0].success', false)) {
              publishPaymentMethodUpdatedECD({
                updateStatus: paymentUpdated,
                attemptedUpdateMethod: ECDUpdateMethodText,
              });
              session.completePayment({
                status: session.STATUS_SUCCESS,
              });
              return dispatch({
                type: APPLEPAY_PAYMENT_METHOD_SAVED,
                payload: {
                  applePaySaved: true,
                },
              });
            }
            publishPaymentMethodUpdatedECD({
              updateStatus: paymentUpdateFailed,
              attemptedUpdateMethod: ECDUpdateMethodText,
            });
            publishOnErrorECD(result.message);
            session.completePayment({
              status: session.STATUS_FAILURE,
            });
            return dispatch({
              payload: {
                applePayFailed: true,
              },
              type: APPLEPAY_PAYMENT_METHOD_SAVED,
            });
          } catch (error) {
            // billing fetch fails
            publishOnErrorECD(error);
            publishPaymentMethodUpdatedECD({
              updateStatus: paymentUpdateFailed,
              attemptedUpdateMethod: ECDUpdateMethodText,
            });
            session.completePayment({
              status: session.STATUS_FAILURE,
            });
            return dispatch({
              payload: {
                applePayFailed: true,
              },
              type: APPLEPAY_PAYMENT_METHOD_SAVED,
            });
          }
        };

        session.oncancel = () => {
          dispatch({
            payload: {
              applePaySaved: false,
            },
            type: APPLEPAY_PAYMENT_METHOD_SAVED,
          });
          publishOnClickCancelECD();
          publishPaymentMethodUpdatedECD({
            updateStatus: paymentUpdateFailed,
            attemptedUpdateMethod: ECDUpdateMethodText,
          });
        };

        session.begin();
      } catch (error) {
        // Cannot create an ApplePay session
        publishOnErrorApplePayECD();
        publishPaymentMethodUpdatedECD({
          updateStatus: paymentUpdateFailed,
          attemptedUpdateMethod: ECDUpdateMethodText,
        });
        dispatch({
          payload: {
            applePayFailed: true,
          },
          type: APPLEPAY_PAYMENT_METHOD_SAVED,
        });
        session.completePayment({
          status: session.STATUS_FAILURE,
        });
      }
    } else {
      // Cannot make ApplePayPayments
      publishPaymentMethodUpdatedECD({
        updateStatus: paymentUpdateFailed,
        attemptedUpdateMethod: ECDUpdateMethodText,
      });
      return dispatch({
        payload: {
          applePayFailed: true,
        },
        type: APPLEPAY_PAYMENT_METHOD_SAVED,
      });
    }
    return null;
  };
