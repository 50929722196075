import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

/*
  This hook uses the product family set in the switchingTo url parameter to establish the offer a customer is attempting to move to.
  If no parameter is set, the value held by the selection store is used. This handles cases where the user has navigated from a product
  panel or from a deep link.
*/
const useTargetOffer = () => {
  const location = useLocation();
  const parameterProvidedFamily = new URLSearchParams(location.search).get('switchingTo');
  const panelsProvidedFamily = useSelector(state => state.selection.family);
  const family = parameterProvidedFamily ?? panelsProvidedFamily;

  const allAvailableOffers = useSelector(state => state.offers ?? { data: {} }, shallowEqual);
  const productFamilyOffers = allAvailableOffers[family];
  const offerId = productFamilyOffers?.trialId ?? productFamilyOffers?.offerId;
  const targetOffer = offerId ? Object.values(allAvailableOffers.data).find(offer => offer.id === offerId) : null;

  return {
    targetOffer,
    targetFamily: family,
    isTrial: !!productFamilyOffers?.trialId,
    isSourcedFromUrl: !!parameterProvidedFamily,
  };
};

export default useTargetOffer;
