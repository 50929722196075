export const trackUpsellStage = (stage, customerId, upsellCorrelationId, offerId, family, newProvider = false) =>
  fetch(
    `/track-payment-stage?stage=${stage}&correlation=${upsellCorrelationId}&offerId=${offerId}&family=${family}&newProvider=${newProvider}`,
    {
      method: 'GET',
      headers: new Headers({
        'x-customerId': customerId,
      }),
    }
  );
