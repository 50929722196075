import styled, { keyframes } from 'styled-components';
import { TitleSmall500 } from '@nebula/global-styles';
import Button from '@nebula/button';

export const PaymentDivider = styled(TitleSmall500)`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 0.625rem;
  margin: 0.75rem 0;
  ::before,
  ::after {
    content: '';
    border-top: 0.0625rem double ${({ theme }) => theme.color.ui.grey[50]};
    align-self: center;
  }
`;

export const SubTitle = styled(TitleSmall500)`
  margin: 0 0 0.25rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin: 0 0 0.5rem;
  }
`;

export const PaymentMethod = styled.div`
  margin: 0 0 1rem;
  padding: 0.5625rem 1rem;
  background-color: ${({ theme }) => theme.color.brand.white};
  border: 0.0625rem solid ${({ theme }) => theme.color.ui.grey[20]};
  border-radius: ${({ theme }) => theme.borderRadius.small.rem};
  box-shadow: ${({ theme }) => theme.elevation.raised10};
  @media ${({ theme }) => theme.media.smallDesktopMin} {
    margin: 0 0 1.5rem;
  }
`;

export const PaymentMethodTitle = styled(TitleSmall500)`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 1rem;
  }
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.span`
  display: none;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  border: 0.125rem solid rgba(255, 255, 255, 0.25);
  border-top-color: ${({ theme }) => theme.color.ui.white};
  border-radius: ${({ theme }) => theme.borderRadius.circle.rem};
  animation: ${spin} 0.75s infinite linear;
  @media ${({ theme }) => theme.media.shouldAnimate} {
    display: flex;
  }
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  font-size: 0.875rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    width: fit-content;
    font-size: 1rem;
  }
`;
