export const Element = {
  CardNumber: 'cardNumber',
  FirstName: 'firstName',
  LastName: 'lastName',
  ExpiryMonth: 'expiryMonth',
  ExpiryYear: 'expiryYear',
  Cvv: 'cvv',
};

export const FamilyName = {
  credit_expert: 'CreditExpert',
  identity: 'Identity Plus',
};

export const InputState = {
  Standard: 'standard',
  Error: 'error',
  Success: 'success',
  Disabled: 'disabled',
};

export const MaxNameLength = 50;

export const MinNameLength = 1;

export const PaymentCard = {
  Existing: 'existing',
  New: 'new',
};

export const PaymentMethodTypes = {
  Card: 'card',
  ApplePay: 'applePay',
};

export const TrialDuration = 30;
