import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { products } from '../../../constants';
import { TrialDuration } from '../constants';

/**
 * Custom hook to calculate billing change information based on the current subscription and upgrade offer.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.upgradeOffer - The upgrade offer details.
 * @param {boolean} params.isTrial - Flag indicating if the current subscription is a trial.
 * @returns {Object} Billing change information.
 * @returns {Object} return.billingStartDate - The calculated start date for the new billing period.
 * @returns {Object} return.billingEndDate - The end date of the current billing period.
 * @returns {boolean} return.isDeferredUpsell - Flag indicating if the upgrade offer is a deferred upsell.
 * @returns {string} return.periodicity - The billing frequency of the upgrade offer, capitalized.
 * @returns {number} return.price - The price of the upgrade offer.
 *
 * @example
 * const { billingStartDate, billingEndDate, isDeferredUpsell, periodicity, price } = useBillingChangeInfo({
 *   upgradeOffer: {
 *     terms: [{ billingFrequency: 'monthly', price: 29.99 }],
 *     family: 'premium'
 *   },
 *   isTrial: true
 * });
 */

const DEFERRED_PRODUCT_MOVEMENTS = {
  [products.credit_expert]: [products.identity],
};

const BREACH_FAMILIES = [products.breach, products.breachPlus];

const useBillingChangeInfo = ({ upgradeOffer, isTrial }) => {
  const currentSubscription = useSelector(state => state.subscriptions.current);

  const { isDeferredUpsell, billingEndDate } = useSelector(state => ({
    isDeferredUpsell: DEFERRED_PRODUCT_MOVEMENTS[currentSubscription.family]?.includes(upgradeOffer.family) ?? false,
    billingEndDate: dayjs(state.billing.nextBillingDate),
  }));

  const { billingFrequency, price } = upgradeOffer.terms?.[0] ?? {};

  const periodicity = billingFrequency ? billingFrequency.charAt(0).toUpperCase() + billingFrequency.slice(1) : null;

  const defermentDays = isTrial ? TrialDuration : 0;
  let billingStartDate;
  if (BREACH_FAMILIES.includes(currentSubscription.family)) {
    billingStartDate = dayjs(currentSubscription.breachDefermentInfo.defermentDt).add(defermentDays, 'day');
  } else if (isDeferredUpsell) {
    billingStartDate = dayjs(billingEndDate).add(1, 'day');
  } else {
    billingStartDate = dayjs().add(defermentDays, 'day');
  }

  return {
    billingStartDate,
    billingEndDate,
    isDeferredUpsell,
    periodicity,
    price,
  };
};

export default useBillingChangeInfo;
