import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RecurlyProvider } from '@recurly/react-recurly';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '@nebula/theme';

import PaymentCaptureMethod from '../PaymentCaptureMethod';
import PaymentContextProvider, { PAYMENT_STAGES, PaymentContext } from './PaymentContextProvider';
import PaymentMethodChange from '../PaymentMethodChange';
import PaymentMethodAuthorization from '../PaymentMethodAuthorization';
import PaymentProcessing from '../PaymentProcessing';
import { getEnv } from '../Context/env';

export const PaymentStages = () => {
  const paymentContext = useContext(PaymentContext);
  const { paymentStage, restartPaymentFlow } = paymentContext;

  switch (paymentStage) {
    case PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD:
      return <PaymentCaptureMethod />;
    case PAYMENT_STAGES.REQUIRES_AUTHORIZATION:
      return <PaymentMethodAuthorization />;
    case PAYMENT_STAGES.REQUIRES_FULFILLMENT:
      return <PaymentProcessing />;
    default:
      restartPaymentFlow();
      return null;
  }
};

export const PaymentMethodUpdateStages = () => {
  const paymentContext = useContext(PaymentContext);
  const { paymentStage, restartPaymentFlow } = paymentContext;

  let stageComponent;

  switch (paymentStage) {
    case PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD:
    case PAYMENT_STAGES.REQUIRES_AUTHORIZATION: // not yet implemented due to backend restrictions
      stageComponent = <PaymentMethodChange />;
      break;
    default:
      restartPaymentFlow();
      stageComponent = null;
      break;
  }

  return <ThemeProvider theme={defaultTheme}>{stageComponent}</ThemeProvider>;
};

const PaymentOrchestrator = ({ upgradeOffer, isTrial, methodUpdateOnly }) => {
  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();

  return (
    <PaymentContextProvider upgradeOffer={upgradeOffer} isTrial={isTrial}>
      <RecurlyProvider publicKey={key}>
        {methodUpdateOnly && <PaymentMethodUpdateStages />}
        {!methodUpdateOnly && <PaymentStages />}
      </RecurlyProvider>
    </PaymentContextProvider>
  );
};

PaymentOrchestrator.propTypes = {
  upgradeOffer: PropTypes.shape({
    family: PropTypes.string,
    name: PropTypes.string,
    upc: PropTypes.string,
    id: PropTypes.string,
    descriptors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  methodUpdateOnly: PropTypes.bool,
  isTrial: PropTypes.bool,
};

PaymentOrchestrator.defaultProps = {
  methodUpdateOnly: false,
  isTrial: false,
};

export default PaymentOrchestrator;
