import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { TitleLarge700, TitleXLarge700 } from '@nebula/global-styles';
import { useMediaQuery } from '@nebula/hooks';

import { FamilyName } from '../constants';

const HeadingText = ({ isTrial, familyType }) => {
  const theme = useContext(ThemeContext);
  const isDesktop = useMediaQuery(theme.media.smallDesktopMin);
  const ProductName = isDesktop ? TitleXLarge700 : TitleLarge700;

  const productElement = <ProductName as="span">{FamilyName[familyType]}</ProductName>;
  return isTrial ? (
    <React.Fragment>Get your free 30 day trial of {productElement}</React.Fragment>
  ) : (
    <React.Fragment>Join {productElement} today</React.Fragment>
  );
};

HeadingText.propTypes = {
  isTrial: PropTypes.bool.isRequired,
  familyType: PropTypes.string.isRequired,
};

export default HeadingText;
