import React from 'react';

const AutomationStubFrame = () => (
  <iframe
    title="Stubbed 3DS"
    style={{ width: '100%', height: '100%', border: 'none' }}
    srcDoc={`
        <!DOCTYPE html>
        <html lang="en">
        <body>
          <p>Stubbed Authentication</p>
          <button id="succeed" data-automation-test-element="auth-stub-succeed">Succeed</button>
          <button id="fail" data-automation-test-element="auth-stub-fail">Fail</button>
          <script>
            document.getElementById('succeed').addEventListener('click', function() {
              window.parent.postMessage({ type: 'succeed-stubbed-auth' }, '*');
            });
            document.getElementById('fail').addEventListener('click', function() {
              window.parent.postMessage({ type: 'fail-stubbed-auth' }, '*');
            });
          </script>
        </body>
        </html>
      `}
  />
);

export default AutomationStubFrame;
