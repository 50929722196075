import React from 'react';
import PropTypes from 'prop-types';
import { ErrorAlert, WarningAlert } from '@nebula/alert';

import { PAYMENT_ERROR_TYPES } from '../../PaymentOrchestrator/constants/paymentErrors';

const PaymentError = ({ error }) => {
  switch (error.type) {
    case PAYMENT_ERROR_TYPES.TOKENIZATION.GENERATING_PAYMENT_TOKEN:
    case PAYMENT_ERROR_TYPES.TOKENIZATION.INITIATING_PURCHASE:
      return (
        <WarningAlert
          title="We could not take your payment"
          message="Please check your card details are correct or try a different method."
          showIcon
        />
      );
    case PAYMENT_ERROR_TYPES.AUTHORIZATION.UNSUCCESSFUL_3DS:
    case PAYMENT_ERROR_TYPES.AUTHORIZATION.UNSUCCESSFUL_PURCHASE_RETRY:
      return (
        <WarningAlert
          title="We could not upgrade your account"
          message="You have not been charged. Please try again."
          showIcon
        />
      );
    case PAYMENT_ERROR_TYPES.FULFILLMENT.INITIATING_FULFILLMENT:
      return (
        <ErrorAlert
          title="We could not upgrade your account"
          message="You have not been charged. Please try again later or contact us for support."
          showIcon
        />
      );
    default:
      return null;
  }
};

PaymentError.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default PaymentError;
