/*
  Responsible for progressing past payment token capture when a customer already has a stored payment method
*/
const paymentMethodChangeTransition = (paymentContext, cardToken) => {
  const { addPaymentToken } = paymentContext;

  addPaymentToken({ paymentMethod: cardToken });
};

export default paymentMethodChangeTransition;
