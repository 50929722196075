import { push } from 'connected-react-router';
import { isMobileApp, upsellTransition, UpsellTransitionStages } from '@experian-uk/web-common-mobile-view-helpers';

import { relativeUrls } from '../../../constants';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';

const paymentCompleteTransition = (paymentContext, fulfillmentStatus) => async dispatch => {
  const { raisePaymentError, restartPaymentFlow } = paymentContext;

  if (fulfillmentStatus === true) {
    // Purchase successful, we should now be able to make a change to the customers subscription
    if (isMobileApp()) {
      upsellTransition({ stage: UpsellTransitionStages.SUCCESS });
    } else {
      dispatch(push(relativeUrls.thanks));
    }
    return true;
  }

  raisePaymentError({
    message: 'UNSUCCESSFUL_FULFILLMENT_ATTEMPT',
    group: PAYMENT_ERROR_GROUPS.FULFILLMENT,
    type: PAYMENT_ERROR_TYPES.FULFILLMENT.INITIATING_FULFILLMENT,
  });
  restartPaymentFlow();

  return false;
};

export default paymentCompleteTransition;
