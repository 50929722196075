import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, propTypes as formProps } from 'redux-form';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Button } from '@experian-uk/corvetteuk-common-ui';
import Cookie from 'js-cookie';

import formStyledComponent from '../../../helpers/formStyledComponent';
import setUser from '../../../actions/auth/setCurrentUser';
import mockTokens from '../../../mockTokens.json';
import FlexContainer from '../../../components/FlexContainer';
import changeStateAction from '../../../actions/uiChangeState';
import { tabKeys } from '../constants';
import { Label, Select } from '../index.styles';

const showRealUserLogin = changeStateAction.bind(null, 'login', { activeTab: tabKeys.realUser });

@reduxForm({
  form: 'acquisition',
  initialValues: {
    subscriptionType: 'identity',
    isTrial: false,
  },
})
@connect(null, dispatch => bindActionCreators({ setUser, push, showRealUserLogin }, dispatch))
export default class CustomUser extends React.Component {
  static propTypes = {
    ...formProps,
    setUser: PropTypes.func.isRequired,
    showRealUserLogin: PropTypes.func.isRequired,
  };

  submit = ({ subscriptionType, isTrial }) => {
    const trialKey = isTrial ? 'trial' : 'notrial';
    const { token } = mockTokens[subscriptionType][trialKey];

    this.props.setUser(token, { subscriptionType, isTrial });

    Cookie.set('authId', token);

    window.location = '/';
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form method="post" onSubmit={handleSubmit(this.submit)}>
        <Label htmlFor="subscriptionType">Subscription Type</Label>
        <Field component={formStyledComponent(Select)} name="subscriptionType">
          <option value="creditExpert">Credit Expert</option>
          <option value="creditExpert_annual">Credit Expert Annual</option>
          <option value="identity">Identity</option>
          <option value="breach">Breach</option>
          <option value="cpp">CPP</option>
          <option value="free">Free</option>
          <option value="free_3ds">Free &amp; requires 3DS</option>
          <option value="identity_3ds">Identity &amp; requires 3DS</option>
        </Field>
        <Label htmlFor="isTrial">Trial</Label>
        <Field
          component={formStyledComponent(Select)}
          name="isTrial"
          normalize={Boolean}
          format={val => (val ? '1' : '0')}
        >
          <option value="1">Yes</option>
          <option value="0">No</option>
        </Field>
        <FlexContainer columns={2} gutter={12}>
          <Button isButton label="Login" />
          <Button
            isButton
            htmlType="button"
            type="secondary"
            label="Use a token"
            onClick={this.props.showRealUserLogin}
          />
        </FlexContainer>
      </form>
    );
  }
}
