import { LOCATION_CHANGE } from 'connected-react-router';

import { FULFILL_SUBSCRIPTION, FULFILLMENT_FAILED, SUBSCRIPTION_FULFILLED } from '../actions/fulfillments/fulfill';

const defaultState = {
  loading: false,
  newJourney: true,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FULFILL_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case SUBSCRIPTION_FULFILLED:
      return {
        ...action.payload,
        loading: false,
        newJourney: false,
      };
    case FULFILLMENT_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case LOCATION_CHANGE:
      return {
        loading: false,
        newJourney: action.payload.location.pathname === '/switch',
      };
    default:
      return state;
  }
};
