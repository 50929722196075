export const PAYMENT_LOG_STEPS = {
  SWITCH_FORM_STARTED: 'SWITCH_FORM_STARTED',
  SWITCH_FORM_FAILED: 'SWITCH_FORM_FAILED',
  SWITCH_FORM_COMPLETE: 'SWITCH_FORM_COMPLETE',
  AUTHORISING_PAYMENT_METHOD: 'AUTHORISING_PAYMENT_METHOD',
  AUTHORISING_PAYMENT_METHOD_FAILED: 'AUTHORISING_PAYMENT_METHOD_FAILED',
  PAYMENT_AUTHORISED: 'PAYMENT_AUTHORISED',
  SUBSCRIPTION_FULFILLMENT_STARTED: 'SUBSCRIPTION_FULFILLMENT_STARTED',
  SUBSCRIPTION_FULFILLMENT_FAILED: 'SUBSCRIPTION_FULFILLMENT_FAILED',
  SUBSCRIPTION_FULFILLED: 'SUBSCRIPTION_FULFILLED',
  THANKS_PAGE_SHOWN: 'THANKS_PAGE_SHOWN',
};
