import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ThemeContext } from 'styled-components';
import { BodyMedium400, BodyLarge400, TitleLarge500, TitleXLarge500, BodyLarge500 } from '@nebula/global-styles';
import { useMediaQuery } from '@nebula/hooks';

import { products } from '../../../constants';
import { FamilyName } from '../constants';
import useBillingChangeInfo from '../hooks/useBillingChangeInfo';
import { PaymentContext } from '../../PaymentOrchestrator/PaymentContextProvider';
import HeadingText from './HeadingText';

dayjs.extend(advancedFormat);

const PaymentHeading = () => {
  const theme = useContext(ThemeContext);
  const { upgradeOffer, isTrial } = useContext(PaymentContext);
  const { isDeferredUpsell, billingEndDate } = useBillingChangeInfo({ upgradeOffer, isTrial });
  const currentSubscription = useSelector(state => state.subscriptions.current);

  const isMovingToIdentity = upgradeOffer.family === products.identity;
  const subscriptionEnd = billingEndDate.format('Do MMMM');
  const isDesktop = useMediaQuery(theme.media.smallDesktopMin);
  const Heading = isDesktop ? TitleXLarge500 : TitleLarge500;
  const SubHeading = isDesktop ? BodyLarge400 : BodyMedium400;

  return (
    <React.Fragment>
      <Heading as="h1">
        <HeadingText isTrial={isTrial} familyType={upgradeOffer.family} />
      </Heading>
      {isTrial && isMovingToIdentity && <SubHeading>With complimentary access to CreditExpert features.</SubHeading>}
      {isDeferredUpsell && (
        <SubHeading>
          You’ll keep {FamilyName[currentSubscription.family]} until{' '}
          <BodyLarge500 as="span">{subscriptionEnd}</BodyLarge500>
        </SubHeading>
      )}
    </React.Fragment>
  );
};

export default PaymentHeading;
