import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '@nebula/theme';
import { TitleMedium500 } from '@nebula/global-styles';
import { Container, Heading, Detail, DetailsContainer, DetailValue } from './paymentInformation.styles';
import { cardDetailsSchema } from '../../../schemas';
import CurrentPaymentMethod from './currentPaymentMethod/currentPaymentMethod';

const PaymentInformation = ({
  validCard,
  mobilePay,
  directDebit,
  card,
  expirationDate,
  isRecycleSplit,
  subscriptionsData,
  initialPaymentMethod,
  showApplePay,
  loading,
}) => (
  <ThemeProvider theme={defaultTheme}>
    <Container>
      <div data-automation-test-element="paymentDetails">
        <Heading>Your payment information</Heading>
        <DetailsContainer>
          {subscriptionsData.map(
            item =>
              item.content && (
                <Detail key={item.id}>
                  <TitleMedium500>
                    {item.label}
                    {item.label && ':'}
                  </TitleMedium500>
                  <DetailValue data-testid={item.label}>{item.content}</DetailValue>
                </Detail>
              )
          )}
        </DetailsContainer>
      </div>
      <CurrentPaymentMethod
        validCard={validCard}
        mobilePay={mobilePay}
        directDebit={directDebit}
        card={card}
        expirationDate={expirationDate}
        isRecycleSplit={isRecycleSplit}
        vendor={mobilePay?.paymentType}
        initialPaymentMethod={initialPaymentMethod}
        showApplePay={showApplePay}
        loading={loading}
      />
    </Container>
  </ThemeProvider>
);

PaymentInformation.propTypes = {
  validCard: PropTypes.bool.isRequired,
  mobilePay: PropTypes.shape({
    paymentType: PropTypes.string,
  }),
  directDebit: PropTypes.shape({
    accountNumber: PropTypes.string,
  }),
  card: PropTypes.shape(cardDetailsSchema),
  expirationDate: PropTypes.string,
  isRecycleSplit: PropTypes.bool.isRequired,
  subscriptionsData: PropTypes.arrayOf(
    PropTypes.shape({
      nextBill: PropTypes.string,
      noFeeSubscription: PropTypes.bool,
      pendingCancellation: PropTypes.bool,
      currentSubscriptionPrice: PropTypes.number,
      nextBillingPrice: PropTypes.number,
      subscription: PropTypes.string,
    })
  ).isRequired,
  initialPaymentMethod: PropTypes.string,
  showApplePay: PropTypes.bool,
  loading: PropTypes.bool,
};

PaymentInformation.defaultProps = {
  card: null,
  expirationDate: null,
  mobilePay: null,
  directDebit: null,
  initialPaymentMethod: 'card',
  showApplePay: false,
  loading: false,
};

export default PaymentInformation;
