import React, { useContext, useRef, useState } from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { RadioButton, RadioButtonType, RadioButtonState } from '@nebula/radio-button';
import { TitleMedium500, BodyLarge400 } from '@nebula/global-styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getEnv } from '../../Context/env';
import { trackSwitchPaymentMethodSelected } from '../../../helpers/switch/analytics';
import InViewComponent from '../../InView';
import { FamilyName, PaymentCard, PaymentMethodTypes } from '../constants';
import { PaymentContext } from '../../PaymentOrchestrator/PaymentContextProvider';
import useExistingPaymentMethod from '../hooks/useExistingPaymentMethod';
import PaymentCardForm from '../PaymentCardForm';
import { SmallCardIcon } from '../PaymentCardForm/Icons';
import { PaymentDivider, SubTitle, PaymentMethod, PaymentMethodTitle, Spinner, ConfirmButton } from './index.styles';

const PaymentMethodEntry = ({ disabled, onSubmit, onSuccess, onFailure, onExistingPaymentMethod }) => {
  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();

  const formRef = useRef(null);
  const paymentContext = useContext(PaymentContext);
  const { isTrial, upgradeOffer } = paymentContext;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentCard.Existing);

  const { existingPaymentType, existingPaymentDetails } = useExistingPaymentMethod();
  const existingCardAvailable = existingPaymentType === PaymentMethodTypes.Card;
  const { creditCardType, cardNumber, cardExpiryDate } = existingPaymentDetails;
  const showApplePay = !!window?.ApplePaySession?.canMakePayments?.();

  const hasValidExistingMethodSelected = selectedMethod =>
    existingCardAvailable && selectedMethod === PaymentCard.Existing;

  const [isFormValid, setIsFormValid] = useState(hasValidExistingMethodSelected(selectedPaymentMethod));
  const handleFormValidityChange = isValid => setIsFormValid(isValid);

  const handlePaymentMethodSelection = value => {
    trackSwitchPaymentMethodSelected(value);
    setSelectedPaymentMethod(value);

    if (hasValidExistingMethodSelected(value)) {
      handleFormValidityChange(true);
    }
  };

  const customer = useSelector(state => state.auth?.currentUser);
  const address = customer?.address?.[0];

  const handleSubmit = () => {
    onSubmit?.();
  };

  const handleSuccess = paymentMethodToken => {
    onSuccess?.(paymentMethodToken);
  };

  const handleFailure = paymentMethodError => {
    onFailure?.(paymentMethodError);
  };

  const handleConfirm = () => {
    if (hasValidExistingMethodSelected(selectedPaymentMethod)) {
      onExistingPaymentMethod?.();
    } else {
      formRef.current?.requestSubmit();
    }
  };

  const confirmButtonText = isTrial ? 'Start my free trial' : `Upgrade to ${FamilyName[upgradeOffer.family]}`;

  return (
    <RecurlyProvider publicKey={key}>
      <Elements>
        <React.Fragment>
          <TitleMedium500 as="h2">Payment details</TitleMedium500>
          <BodyLarge400>{isTrial && 'No payment is taken during your trial'}</BodyLarge400>
        </React.Fragment>
        {showApplePay && (
          <React.Fragment>
            <apple-pay-button buttonstyle="black" type="pay" locale="en-GB" />
            <PaymentDivider>or</PaymentDivider>
          </React.Fragment>
        )}
        <InViewComponent label="switch_payment_form">
          <SubTitle as="h3">Pay by card</SubTitle>
        </InViewComponent>
        {existingCardAvailable ? (
          <React.Fragment>
            <PaymentMethod>
              <RadioButton
                id={PaymentCard.Existing}
                label={
                  <PaymentMethodTitle>
                    <SmallCardIcon creditCardType={creditCardType} /> •••• {cardNumber} | Expires {cardExpiryDate}
                  </PaymentMethodTitle>
                }
                value={PaymentCard.Existing}
                index={1}
                radioType={RadioButtonType.Standard}
                radioState={RadioButtonState.Standard}
                width="100%"
                isChecked={selectedPaymentMethod === PaymentCard.Existing}
                isDisabled={false}
                onRadioButtonSelected={() => handlePaymentMethodSelection(PaymentCard.Existing)}
              />
            </PaymentMethod>
            <PaymentMethod>
              <RadioButton
                id={PaymentCard.New}
                label={<PaymentMethodTitle>Use a different card</PaymentMethodTitle>}
                value={PaymentCard.New}
                index={2}
                radioType={RadioButtonType.Standard}
                radioState={RadioButtonState.Standard}
                width="100%"
                isChecked={selectedPaymentMethod === PaymentCard.New}
                isDisabled={false}
                onRadioButtonSelected={() => handlePaymentMethodSelection(PaymentCard.New)}
                data-automation-test-element="use-different-card"
              />
              {selectedPaymentMethod === PaymentCard.New && (
                <PaymentCardForm
                  ref={formRef}
                  customer={customer}
                  address={address}
                  onSubmitAttempt={handleSubmit}
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  onFormValidityChange={handleFormValidityChange}
                />
              )}
            </PaymentMethod>
          </React.Fragment>
        ) : (
          <PaymentMethod>
            <PaymentCardForm
              ref={formRef}
              customer={customer}
              address={address}
              onSubmitAttempt={handleSubmit}
              onSuccess={handleSuccess}
              onFailure={handleFailure}
              onFormValidityChange={handleFormValidityChange}
            />
          </PaymentMethod>
        )}
        <ConfirmButton
          data-automation-test-element="confirmation-button"
          disabled={!isFormValid || disabled}
          label={disabled ? <Spinner /> : confirmButtonText}
          buttonKind="primaryMagenta"
          onClick={handleConfirm}
        />
      </Elements>
    </RecurlyProvider>
  );
};

PaymentMethodEntry.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onExistingPaymentMethod: PropTypes.func.isRequired,
};

export default PaymentMethodEntry;
