import React, { useContext, useState } from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@nebula/hooks';
import { ThemeContext } from 'styled-components';

import PaymentCardForm from '../PaymentCaptureMethod/PaymentCardForm';
import { PaymentMethod, Spinner } from '../PaymentCaptureMethod/PaymentMethodEntry/index.styles';
import { getEnv } from '../Context/env';
import ExistingPaymentDetails from './ExistingPaymentDetails';
import paymentMethodChangeTransition from '../PaymentOrchestrator/transitions/paymentMethodChangeTransition';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import {
  LargePaymentChangeTitle,
  LargePaymentInfoTitle,
  MethodUpdateButton,
  PaymentChangeButtons,
  PaymentInformationContainer,
  StandardPaymentChangeTitle,
  StandardPaymentInfoTitle,
} from './index.styles';

const PaymentMethodChange = () => {
  const { REACT_APP_RECURLY_PUBLIC_KEY: key } = getEnv();

  const paymentContext = useContext(PaymentContext);

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [processingMethodUpdate, setProcessingMethodUpdate] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const theme = useContext(ThemeContext);
  const useLargerCopy = useMediaQuery(theme.media.tabletPortraitMin);
  const PaymentInformationTitle = useLargerCopy ? LargePaymentInfoTitle : StandardPaymentInfoTitle;
  const ChangePaymentTitle = useLargerCopy ? LargePaymentChangeTitle : StandardPaymentChangeTitle;

  const handleFormValidityChange = isValid => setIsFormValid(isValid);

  const customer = useSelector(state => state.auth?.currentUser);
  const address = customer?.address?.[0];

  const handleCancel = () => {
    setProcessingMethodUpdate(false);
    setShowPaymentForm(false);
  };

  const handleSubmit = () => {
    setProcessingMethodUpdate(true);
  };

  const handleSuccess = paymentMethodToken => {
    paymentMethodChangeTransition(paymentContext, paymentMethodToken.id);
    setProcessingMethodUpdate(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleFailure = _paymentMethodError => {
    setProcessingMethodUpdate(false);
  };

  return (
    <PaymentInformationContainer>
      <PaymentInformationTitle>Your payment information</PaymentInformationTitle>
      <ExistingPaymentDetails onTriggerChangeMethod={() => setShowPaymentForm(true)} disabled={showPaymentForm} />
      {showPaymentForm && (
        <React.Fragment>
          <ChangePaymentTitle>Change payment method</ChangePaymentTitle>
          <RecurlyProvider publicKey={key}>
            <Elements>
              <PaymentMethod>
                <PaymentCardForm
                  customer={customer}
                  address={address}
                  onSubmitAttempt={handleSubmit}
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  onFormValidityChange={handleFormValidityChange}
                />
              </PaymentMethod>
              {paymentContext.tokens.paymentMethod && (
                <p>Created Token: {paymentContext.tokens.paymentMethod} - 3DS/Account update not yet implemented.</p>
              )}
              <PaymentChangeButtons>
                <MethodUpdateButton buttonKind="secondaryBlue" label="Cancel" type="button" onClick={handleCancel} />
                <MethodUpdateButton
                  data-automation-test-element="confirmation-method-change-button"
                  disabled={!isFormValid || processingMethodUpdate}
                  label={processingMethodUpdate ? <Spinner /> : 'Save card'}
                  buttonKind="primaryBlue"
                  type="submit"
                  form="recurly-payment-form"
                />
              </PaymentChangeButtons>
            </Elements>
          </RecurlyProvider>
        </React.Fragment>
      )}
    </PaymentInformationContainer>
  );
};

export default PaymentMethodChange;
