import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AutomationStubFrame from './iframe';

const AutomationAuthStub = ({ onToken, onError }) => {
  useEffect(() => {
    const handleMessage = event => {
      if (event.data.type === 'succeed-stubbed-auth') {
        onToken({ id: 'unique-token-id' });
      }

      if (event.data.type === 'fail-stubbed-auth') {
        onError(new Error('Operation failed'));
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return <AutomationStubFrame />;
};

AutomationAuthStub.propTypes = {
  onToken: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AutomationAuthStub;
