import Button from '@nebula/button';
import styled from 'styled-components';

export const InformationCard = styled.div`
  border: 1px solid ${({ theme }) => theme.color.ui.grey[20]};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export const PaymentMethodDetails = styled(InformationCard)`
  background-color: ${({ theme }) => theme.color.ui.grey[10]};
`;

export const Entry = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

export const ChangePaymentMethodButton = styled(Button)`
  align-self: flex-start;
  font-size: 1rem;
  width: 100%;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    padding: 1.5rem;
    width: fit-content;
    margin-top: 0.5rem;
  }
`;
