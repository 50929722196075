import React from 'react';
import PropTypes from 'prop-types';
import { ThreeDsContainer } from './index.styles';
import AutomationAuthStub from './AutomationStub';
import { getEnv } from '../../Context/env';

const AuthorizationPrompt = ({ actionTokenId, onToken, onError }) => {
  const env = getEnv();
  const shouldMockAuth = env.REACT_APP_ENV !== 'prod' && window.Cypress?.isIntegrationTest;

  if (shouldMockAuth) {
    return <AutomationAuthStub onToken={onToken} onError={onError} />;
  }

  return <ThreeDsContainer actionTokenId={actionTokenId} onToken={onToken} onError={onError} />;
};

AuthorizationPrompt.propTypes = {
  actionTokenId: PropTypes.string.isRequired,
  onToken: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AuthorizationPrompt;
