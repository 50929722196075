import { useRef } from 'react';
import { Element, InputState } from '../../constants';
import {
  trackSwitchPaymentCardExpiryMonthValid,
  trackSwitchPaymentCardExpiryYearValid,
  trackSwitchPaymentCardNumValid,
  trackSwitchPaymentCvvValid,
  trackSwitchPaymentFirstNameValid,
  trackSwitchPaymentLastNameValid,
} from '../../../../helpers/switch/analytics';

/* Configures analytics to fire against a form field when it moves from an invalid to a valid state, this process is repeatable */
const useFieldAnalytics = inputStates => {
  const shouldFireRef = useRef({});

  const trackOnValidState = (inputField, analyticsFunction) => {
    if (inputStates[inputField] === InputState.Success && shouldFireRef.current[inputField] !== false) {
      analyticsFunction();
      shouldFireRef.current[inputField] = false;
    } else if (inputStates[inputField] !== InputState.Success) {
      shouldFireRef.current[inputField] = true;
    }
  };

  trackOnValidState(Element.CardNumber, trackSwitchPaymentCardNumValid);
  trackOnValidState(Element.FirstName, trackSwitchPaymentFirstNameValid);
  trackOnValidState(Element.LastName, trackSwitchPaymentLastNameValid);
  trackOnValidState(Element.ExpiryMonth, trackSwitchPaymentCardExpiryMonthValid);
  trackOnValidState(Element.ExpiryYear, trackSwitchPaymentCardExpiryYearValid);
  trackOnValidState(Element.Cvv, trackSwitchPaymentCvvValid);
};

export default useFieldAnalytics;
