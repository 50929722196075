import Button from '@nebula/button';
import { TitleLarge500, TitleSmall500, TitleXLarge500 } from '@nebula/global-styles';
import styled from 'styled-components';

export const PaymentInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 49.75rem;
  margin-top: 3.75rem;
  margin-bottom: 2rem;
`;

export const PaymentChangeButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    gap: 1.5rem;
  }
`;

export const MethodUpdateButton = styled(Button)`
  font-size: 0.875rem;
  width: 100%;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    font-size: 1rem;
    width: 11.375rem;
  }
`;

export const LargePaymentInfoTitle = styled(TitleXLarge500)`
  margin: 0 0 1.5rem;
`;

export const StandardPaymentInfoTitle = styled(TitleLarge500)`
  margin: 0 0 1rem;
`;

export const LargePaymentChangeTitle = styled(TitleLarge500)`
  margin: 0 0 1.5rem;
`;

export const StandardPaymentChangeTitle = styled(TitleSmall500)`
  margin: 0 0 0.5rem;
`;
