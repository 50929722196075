import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BaseCard } from '@nebula/cards';
import defaultTheme from '@nebula/theme';
import { ErrorAlert } from '@nebula/alert';
import { errorMessages } from '../../../../constants';
import { getCardNumberEnding } from '../../../../helpers/billing';
import { cardDetailsSchema } from '../../../../schemas';
import { CardAndDDContainer, MobilePayContainer, ChangePaymentMethodButton } from './currentPaymentMethod.styles';
import { CardDetails, DirectDebitDetails, MobilePayDetails } from './paymentMethodDetails';
import {
  ApplePayPaymentMethodUpdateFailedAlert,
  PaymentMethodRecycleAlert,
  PaymentMethodSuccessAlert,
} from './paymentMethodAlerts';
import uiChangeState from '../../../../actions/uiChangeState';
import { selectPage } from '../../../../reducers/uiState';
import { publishChangePaymentMethodECD } from '../paymentInformationsEcds';

const CurrentPaymentMethod = ({
  validCard,
  mobilePay,
  directDebit,
  card,
  expirationDate,
  isRecycleSplit,
  vendor,
  showChangePaymentMethod,
  showChangePaymentSuccess,
  showApplePayChangePaymentFail,
  uiChangeStateAction,
  showApplePay,
  initialPaymentMethod,
  loading,
}) => {
  const showCurrentCard = validCard && !mobilePay && !directDebit;
  const showMobilePay = !validCard && mobilePay && !directDebit;
  const showDirectDebit = !validCard && !mobilePay && directDebit;
  const showTechnicalError = !validCard && !mobilePay && !directDebit && !loading;
  const PaymentDetailsContainer = showMobilePay ? MobilePayContainer : CardAndDDContainer;
  const onChangePaymentMethodClick = event => {
    event.preventDefault();
    uiChangeStateAction(window.location.pathname, {
      showChangePaymentMethod: true,
      showChangePaymentSuccess: false,
      showApplePayChangePaymentFail: false,
    });
    publishChangePaymentMethodECD(showApplePay ? 'applepay & card' : 'card', initialPaymentMethod);
  };

  return (
    <BaseCard cardType="outlined" backgroundColor={defaultTheme.color.ui.grey[10]}>
      {showTechnicalError && (
        <CardAndDDContainer data-automation-test-element="alertMessage">
          <ErrorAlert showIcon message={errorMessages.billing.paymentDetails} />
        </CardAndDDContainer>
      )}
      <PaymentDetailsContainer>
        {showCurrentCard && (
          <CardDetails
            cardType={card?.creditCardType}
            endingNumbers={getCardNumberEnding(card?.creditCardNumber)}
            expiryDate={expirationDate}
          />
        )}
        {showDirectDebit && (
          <DirectDebitDetails accountNumber={`xxxx${getCardNumberEnding(directDebit?.accountNumber)}`} />
        )}
        {showMobilePay && <MobilePayDetails vendor={vendor} />}
        {isRecycleSplit && <PaymentMethodRecycleAlert />}
        {showChangePaymentSuccess && <PaymentMethodSuccessAlert />}
        {showApplePayChangePaymentFail && <ApplePayPaymentMethodUpdateFailedAlert />}
        <ChangePaymentMethodButton
          onClick={event => onChangePaymentMethodClick(event)}
          label="Change payment method"
          buttonKind="secondaryBlue"
          disabled={showChangePaymentMethod || loading}
          testId="changePaymentMethodButton"
          data-automation-test-element="changePaymentMethodButton"
        />
      </PaymentDetailsContainer>
    </BaseCard>
  );
};

CurrentPaymentMethod.propTypes = {
  validCard: PropTypes.bool,
  mobilePay: PropTypes.shape({
    paymentType: PropTypes.string,
  }),
  directDebit: PropTypes.shape({
    accountNumber: PropTypes.string,
  }),
  card: PropTypes.shape(cardDetailsSchema),
  expirationDate: PropTypes.string,
  isRecycleSplit: PropTypes.bool.isRequired,
  vendor: PropTypes.oneOf(['ApplePay', 'GooglePay']),
  showChangePaymentMethod: PropTypes.bool,
  showChangePaymentSuccess: PropTypes.bool,
  showApplePayChangePaymentFail: PropTypes.bool,
  uiChangeStateAction: PropTypes.func,
  showApplePay: PropTypes.bool,
  initialPaymentMethod: PropTypes.string,
  loading: PropTypes.bool,
};

CurrentPaymentMethod.defaultProps = {
  vendor: null,
  validCard: null,
  directDebit: null,
  card: null,
  expirationDate: null,
  mobilePay: null,
  showChangePaymentMethod: false,
  showChangePaymentSuccess: false,
  showApplePayChangePaymentFail: false,
  uiChangeStateAction: undefined,
  showApplePay: false,
  initialPaymentMethod: 'card',
  loading: false,
};

const mapReduxStateToProps = state => ({
  showChangePaymentMethod: selectPage(state.uiState, window.location.pathname).showChangePaymentMethod,
  showChangePaymentSuccess: selectPage(state.uiState, window.location.pathname).showChangePaymentSuccess,
  showApplePayChangePaymentFail: selectPage(state.uiState, window.location.pathname).showApplePayChangePaymentFail,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uiChangeStateAction: uiChangeState,
    },
    dispatch
  );
export default connect(mapReduxStateToProps, mapDispatchToProps)(CurrentPaymentMethod);
