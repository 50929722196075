import { useSelector, useDispatch, connect } from 'react-redux';
import React, { useCallback } from 'react';
import creditCardType from 'credit-card-type';
import { Field, Fields, reduxForm, change, formValueSelector } from 'redux-form';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import { ErrorAlert } from '@nebula/alert';
import { cardTypes } from '../../../../constants';

import { validateField, normalizeField } from '../../../../helpers/forms';
import { hiddenInput } from '../../../../helpers/formStyledComponent';
import { expiryDateFields } from '../../../NewCard/coreFields';
import validExpiryDate from '../../../NewCard/validExpiryDate';
import submit from '../../../NewCard/submit';
import CardExpiryDate from './cardExpiryDate';
import TextFieldInput from './textFieldInput';
import { StyledCardNumberIcon, CardSecurityCodeIcon, Form } from './updateCardDetails.styles';
import validateCardExpiryDate from '../../../NewCard/validateCardExpiryDate';

const form = 'productMovement/newCard';
const formSelector = formValueSelector(form);
const expiryDate = expiryDateFields(true);

let UpdateCardDetails = () => {
  const errors = useSelector(state => state.error);
  const dispatch = useDispatch();

  const changeEvents = {
    creditCardNumber: number => {
      if (!number) {
        dispatch(change(form, 'creditCardType', ''));
        return;
      }

      const possibleTypes = creditCardType(number);
      const currentType = possibleTypes.find(t => cardTypes.includes(t.type));

      if (currentType) {
        dispatch(change(form, 'creditCardType', currentType.type));
      }
    },
  };

  const handleChange = useCallback(e => {
    const { name, value } = e.currentTarget;
    if (changeEvents[name]) {
      changeEvents[name](value);
    }
  }, []);

  const cardSaveError = errors?.find(err => err.type === 'CardSaveError');
  const errorMessage = cardSaveError?.error;
  let parseError;
  if (errorMessage) {
    const parseErrorHeading = JSON.parse(errorMessage).heading;
    const parseErrorMessages = JSON.parse(errorMessage)?.messages;
    parseError = parseErrorMessages ? `${parseErrorHeading} ${parseErrorMessages}` : parseErrorHeading;
  }

  return (
    <Form method="post" {...setAutomationElement('newCardForm')} data-hook="fs-exclude-element">
      <Field
        name="creditCardNumber"
        automation="newCardNumber"
        component={TextFieldInput}
        errorMessageProps={{
          required: ['Please enter your Visa or Mastercard number'],
          format: ['Please enter a valid Visa or Mastercard number'],
        }}
        inputProps={{
          label: 'Card number',
          maxLength: 19,
          iconLeft: <StyledCardNumberIcon />,
        }}
        onChange={handleChange}
        validate={validateField.cardNumber}
        normalize={normalizeField.cardNumber}
      />
      <Field
        automation="newCardName"
        component={TextFieldInput}
        errorMessageProps={{
          required: ['Please enter the name on your card'],
        }}
        inputProps={{
          label: 'Name shown on card',
          maxLength: 150,
        }}
        name="nameOnCreditCard"
        onChange={handleChange}
        validate={validateField.required}
      />
      <Fields names={Object.keys(expiryDate.options)} component={CardExpiryDate} validate={validExpiryDate} />
      <Field
        automation="newCardCvv"
        component={TextFieldInput}
        errorMessageProps={{
          required: ['Please enter your CVC/CVV number'],
          format: ['Please enter a valid CVC/CVV number'],
        }}
        inputProps={{
          label: 'Security code',
          helperTop: 'The last 3 digits on the signature strip on the back of your card.',
          iconLeft: <CardSecurityCodeIcon />,
          maxLength: 3,
          placeholder: 'CVC/CVV',
        }}
        name="CVV"
        onChange={handleChange}
        normalize={normalizeField.numeric}
        validate={validateField.cvvNumber}
      />
      <Field component={hiddenInput} name="creditCardType" {...setAutomationElement('creditCardType')} />
      {parseError && <ErrorAlert message={parseError} showIcon />}
    </Form>
  );
};

const mapStateToProps = state => ({
  currentCreditCardType: formSelector(state, 'creditCardType'),
  errors: state.error,
});

UpdateCardDetails = reduxForm({
  form: 'productMovement/newCard',
  initialValues: {
    creditCardNumber: '',
    nameOnCreditCard: '',
    expirationMonth: '',
    expirationYear: '',
    CVV: '',
    creditCardType: '',
  },
  validate: validateCardExpiryDate,
  onSubmit: submit,
})(UpdateCardDetails);

export default connect(mapStateToProps)(UpdateCardDetails);
