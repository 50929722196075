import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TitleSmall400, TitleSmall500, TitleXSmall400, TitleXSmall500 } from '@nebula/global-styles';
import { useMediaQuery } from '@nebula/hooks';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ThemeContext } from 'styled-components';

import { ChangePaymentMethodButton, Entry, InformationCard, PaymentMethodDetails } from './index.styles';
import { familyName } from '../../../constants';
import useExistingPaymentMethod from '../../PaymentCaptureMethod/hooks/useExistingPaymentMethod';
import { PaymentMethodTypes } from '../../PaymentCaptureMethod/constants';
import { SmallCardIcon } from '../../PaymentCaptureMethod/PaymentCardForm/Icons';

dayjs.extend(advancedFormat);

const InformationRow = ({ label, value, testId }) => {
  const theme = useContext(ThemeContext);
  const useLargerCopy = useMediaQuery(theme.media.tabletPortraitMin);

  const EntryTitle = useLargerCopy ? TitleSmall500 : TitleXSmall500;
  const EntryValue = useLargerCopy ? TitleSmall400 : TitleXSmall400;

  return (
    <Entry>
      <EntryTitle>{label}:</EntryTitle> <EntryValue data-automation-test-element={testId}>{value}</EntryValue>
    </Entry>
  );
};

const ExistingPaymentDetails = ({ onTriggerChangeMethod, disabled }) => {
  const currentSubscription = useSelector(state => state.subscriptions?.current?.family);

  const { existingPaymentType, existingPaymentDetails } = useExistingPaymentMethod();

  const nextBillingDate = useSelector(state => state.billing?.nextBillingDate);
  const billingPrice = useSelector(state => state.billing?.signupPrice);
  const monthlyBilling = useSelector(state => state.billing?.billingFrequency === 'Monthly');
  const billingDay = dayjs(nextBillingDate).format('Do');
  const nextBillDateLong = dayjs(nextBillingDate).format('Do MMMM YYYY');

  return (
    <React.Fragment>
      <InformationCard>
        {currentSubscription && (
          <InformationRow label="Subscription" value={familyName[currentSubscription]} testId="YourSubscription" />
        )}
        {billingPrice && <InformationRow label="Price" value={`£${billingPrice}`} testId="YourPrice" />}
        {nextBillingDate && monthlyBilling && billingDay && (
          <InformationRow label="Billing date" value={`${billingDay} of each month`} testId="YourBillingDate" />
        )}
        {nextBillingDate && nextBillDateLong && (
          <InformationRow label="Next bill" value={nextBillDateLong} testId="YourNextBill" />
        )}
      </InformationCard>
      {existingPaymentType === PaymentMethodTypes.Card && (
        <PaymentMethodDetails>
          <InformationRow
            label="Card type"
            value={<SmallCardIcon creditCardType={existingPaymentDetails.creditCardType} />}
          />
          <InformationRow label="Card ending" value={existingPaymentDetails.cardNumber} />
          <InformationRow label="Expires" value={existingPaymentDetails.cardExpiryDateLong} />
          <ChangePaymentMethodButton
            type="button"
            label="Change payment method"
            buttonKind="secondaryBlue"
            disabled={disabled}
            onClick={onTriggerChangeMethod}
          />
        </PaymentMethodDetails>
      )}
    </React.Fragment>
  );
};

InformationRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

InformationRow.defaultProps = {
  testId: '',
};

ExistingPaymentDetails.propTypes = {
  onTriggerChangeMethod: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ExistingPaymentDetails;
