import styled, { css } from 'styled-components';
import { TitleMedium500, BodyLarge400 } from '@nebula/global-styles';

const Column = css`
  display: grid;
  grid-auto-columns: minmax(auto, 596px);
  align-items: center;
  justify-content: center;
`;

const Padding = css`
  padding: 0 1rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    padding: 0;
  }
`;

export const Alert = styled.aside`
  ${Column};
  ${Padding};
  margin: 1.5rem 0 0;
`;

export const Header = styled.header`
  ${Column}
  ${Padding}
  margin: 1.5rem 0 1rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin: 2rem 0 1rem;
  }
  @media ${({ theme }) => theme.media.smallDesktopMin} {
    margin: 3rem 0 1rem;
  }
`;

export const Main = styled.main`
  display: grid;
  grid-gap: 1.5rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    grid-gap: 2rem;
  }
`;

export const Review = styled.section`
  ${Column}
  ${Padding}
`;

export const Methods = styled.section`
  ${Column}
  padding: 1rem 1rem 1.5rem;
  background-color: ${({ theme }) => theme.color.ui.grey[10]};
  > ${TitleMedium500} {
    margin: 0.5rem 0 0.25rem;
  }
  > ${BodyLarge400} {
    margin: 0 0 1rem;
  }
  apple-pay-button {
    --apple-pay-button-width: 100%;
    --apple-pay-button-height: 3rem;
    --apple-pay-button-padding: 0.5rem 1rem;
    --apple-pay-button-border-radius: 0.375rem;
    --apple-pay-button-box-sizing: border-box;
    @media ${({ theme }) => theme.media.smallDesktopMin} {
      --apple-pay-button-width: 23rem;
    }
  }
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    padding: 1.5rem 0 2.5rem;
  }
  @media ${({ theme }) => theme.media.smallDesktopMin} {
    padding: 1.5rem 0 3.75rem;
    > ${BodyLarge400} {
      margin: 0 0 1.5rem;
    }
  }
`;
