import { trackSwitchInitiatePurchase } from '../../../helpers/switch/analytics';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import startPurchase from '../helpers/startPurchase';
import { PAYMENT_STAGES } from '../PaymentContextProvider';

/*
  Responsible for moving from the payment method capture stage to:
  - Initiate a purchase with an existing payment method which does not require authorization
  - Fulfillment 
*/
const paymentMethodExistsTransition = paymentContext => async dispatch => {
  const { upgradeOffer, goToPaymentStage, raisePaymentError, restartPaymentFlow } = paymentContext;

  const { complete, error } = await dispatch(
    startPurchase({
      targetOfferId: upgradeOffer.id,
    })
  );

  if (complete) {
    trackSwitchInitiatePurchase(true);
    goToPaymentStage(PAYMENT_STAGES.REQUIRES_FULFILLMENT);
  } else {
    trackSwitchInitiatePurchase(false);
    raisePaymentError({
      message: error,
      group: PAYMENT_ERROR_GROUPS.TOKENIZATION,
      type: PAYMENT_ERROR_TYPES.TOKENIZATION.INITIATING_PURCHASE,
    });
    restartPaymentFlow();
  }
};

export default paymentMethodExistsTransition;
