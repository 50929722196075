import get from 'lodash/get';
import fetch from '../fetch';
import { fulfillmentErrorMessages } from './errors';
import { channels, products } from '../../constants';
import { defined } from '../../helpers/defined';

export const FULFILL_SUBSCRIPTION = 'FULFILL_SUBSCRIPTION';
export const SUBSCRIPTION_FULFILLED = 'SUBSCRIPTION_FULFILLED';
export const FULFILLMENT_FAILED = 'FULFILLMENT_FAILED';
export const fulfillmentEndpoint = 'fulfillments';

const defaultChannel = channels.online;

export default (offerId, channel = defaultChannel, errorMessageKey = undefined) =>
  async (dispatch, getState) => {
    const currentState = getState();

    dispatch({ type: FULFILL_SUBSCRIPTION, meta: { clearErrors: true } });

    const errorMessage = JSON.stringify(fulfillmentErrorMessages[errorMessageKey] || fulfillmentErrorMessages.default);

    if (!defined('string', offerId)) {
      return dispatch({
        type: FULFILLMENT_FAILED,
        error: true,
        payload: new Error(errorMessage),
      });
    }

    const isAmend =
      currentState.productDetails.isBreach &&
      [products.breach, products.breachPlus].includes(currentState.selection.family);
    const request = {
      method: `${isAmend ? 'PUT' : 'POST'}`,
      body: {
        channel,
        offerId,
      },
    };
    const url = `/${fulfillmentEndpoint}${isAmend ? '/amend' : ''}`;

    let fulfillmentResultAction = {};

    try {
      const fulfilled = await dispatch(fetch(url, request));
      const succeeded = !!get(fulfilled, 'data[0].subscriptionId', false);

      fulfillmentResultAction = {
        type: SUBSCRIPTION_FULFILLED,
        payload: { success: succeeded },
      };
    } catch (error) {
      fulfillmentResultAction = {
        type: FULFILLMENT_FAILED,
        error: true,
        payload: new Error(errorMessage),
      };
    }

    return dispatch(fulfillmentResultAction);
  };
