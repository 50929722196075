import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import { trackUpsellStage } from '../../../helpers/trackUpsell/trackUpsellStage';
import { PaymentContext } from '../PaymentContextProvider';
import BILLING_PROVIDERS from '../../../constants/billingProviders';

const useTrackUpsellStage = () => {
  const { upgradeOffer } = useContext(PaymentContext);
  const newProvider = useSelector(state => state.billing.billingProvider === BILLING_PROVIDERS.NEW);
  const upsellCorrelationId = useSelector(state => state.upsellProgress.upsellCorrelationId);
  const customerId = useSelector(state => state.auth.currentUser.customerId);

  return useCallback(
    upsellStage => {
      if (!upsellCorrelationId) return;
      trackUpsellStage(upsellStage, customerId, upsellCorrelationId, upgradeOffer.id, upgradeOffer.family, newProvider);
    },
    [customerId, upsellCorrelationId, upgradeOffer, newProvider]
  );
};

export default useTrackUpsellStage;
