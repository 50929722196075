import React, { createContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import usePaymentErrors from './hooks/usePaymentErrors';
import usePaymentTokens from './hooks/usePaymentTokens';
import usePaymentStage from './hooks/usePaymentStage';

export const PAYMENT_STAGES = Object.freeze({
  REQUIRES_PAYMENT_METHOD: 0,
  REQUIRES_AUTHORIZATION: 1,
  REQUIRES_FULFILLMENT: 2,
});

export const DEFAULT_TOKENS_STATE = Object.freeze({
  paymentMethod: null,
  authorizationInput: null,
  authorizationOutput: null,
});

export const PaymentContext = createContext();

/* 
  PaymentContextProvider holds the state of the payment journey but does not handle any logic on when we should move
  between stages or trigger side effects. State is accessible via PaymentContext in the stages themselves.

  Please be mindful of what state is added to the context - the values stored here should be required between multiple stages,
  if it's only required in one stage it should be stored in that stage's state.
*/
const PaymentContextProvider = ({ upgradeOffer, isTrial, children }) => {
  const { paymentErrors, raisePaymentError, clearPaymentErrors } = usePaymentErrors();
  const { tokens, addPaymentToken, setTokens } = usePaymentTokens();
  const { paymentStage, goToPaymentStage } = usePaymentStage();

  const restartPaymentFlow = useCallback(() => {
    setTokens(DEFAULT_TOKENS_STATE);
    goToPaymentStage(PAYMENT_STAGES.REQUIRES_PAYMENT_METHOD);
  }, [setTokens, goToPaymentStage]);

  const contextValue = useMemo(
    () => ({
      upgradeOffer,
      isTrial,
      paymentErrors,
      raisePaymentError,
      clearPaymentErrors,
      restartPaymentFlow,
      paymentStage,
      goToPaymentStage,
      tokens,
      addPaymentToken,
    }),
    [
      upgradeOffer,
      isTrial,
      paymentErrors,
      raisePaymentError,
      clearPaymentErrors,
      restartPaymentFlow,
      paymentStage,
      goToPaymentStage,
      tokens,
      addPaymentToken,
    ]
  );

  return <PaymentContext.Provider value={contextValue}>{children}</PaymentContext.Provider>;
};

PaymentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  upgradeOffer: PropTypes.shape({
    family: PropTypes.string,
    name: PropTypes.string,
    upc: PropTypes.string,
    id: PropTypes.string,
    descriptors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  isTrial: PropTypes.bool.isRequired,
};

export default PaymentContextProvider;
