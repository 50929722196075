import styled from 'styled-components';

export const Summary = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.decorative.sapphire[10]};
  border-radius: 0.75rem;
  a {
    white-space: nowrap;
  }
  @media ${({ theme }) => theme.media.smallDesktopMin} {
    padding: 1.5rem;
  }
`;

export const Product = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0.25rem 0 0;
`;

export const Divider = styled.hr`
  height: 0.0625rem;
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.color.decorative.sapphire[20]};
`;
