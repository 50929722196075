import { BodyLarge400, BodyLarge500, TitleSmall500 } from '@nebula/global-styles';

import styled from 'styled-components';

export const Guidance = styled.div`
  background-color: ${({ theme }) => theme.color.decorative.sapphire[10]};
  border-radius: 0.75rem;

  ${TitleSmall500} {
    margin: 0 0 4px 0;
  }

  ${BodyLarge400}, ${BodyLarge500} {
    margin: 0;
  }

  margin-bottom: 0.5rem;
  max-width: 596px;
  padding: 1rem;
`;
