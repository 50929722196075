import { ThreeDSecureAction } from '@recurly/react-recurly';
import styled from 'styled-components';

export const ThreeDsContainer = styled(ThreeDSecureAction)`
  border: 1px solid ${({ theme }) => theme.color.ui.grey[20]};
  border-radius: 0.75rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  height: 25rem;
  padding: 16px;
`;
